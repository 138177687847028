<template>
  <div class="ma-4">
    <img :src="`${url}banner.png`" class="paid-pr-banner" />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PaidPrBanner',
  setup() {
    let env = '';
    switch (window.location.hostname.split('.')[0]) {
      case 'tval':
        break;
      case 'stg.':
        env = 'stg';
        break;
      default:
        env = 'dev.';
        break;
    }
    const url = `https://file.${env}tval.jp/`;
    return { url };
  }
});
</script>

<style scoped>
.paid-pr-banner {
  max-width: 1286px;
  max-height: 120px;
  min-width: 806px;
  min-height: 75.2px;
  width: 100%;
  height: auto;
}
</style>
