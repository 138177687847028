<template>
  <div ref="box" style="width: 100%">
    <Flex v-if="isTimeScreen" justify-center align-center height="210px">
      <TimeoutScreen
        reload-class="mt-1"
        message-class="mt-0"
        @reload="$emit('reload')"
      />
    </Flex>
    <Flex
      v-else-if="isLoading && !isTimeScreen"
      justify-center
      align-center
      height="210px"
    >
      <BarsSvg />
    </Flex>
    <Chart
      v-else-if="!isLoading && !isTimeScreen"
      :style="{ width: width + 'px' }"
      :options="chartOptions"
      :class="{ zerohit: data.length === 0 }"
    />
  </div>
</template>
<script>
import { format } from 'date-fns';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import { computed, defineComponent, ref, onMounted } from 'vue';

import BarsSvg from '@/assets/images/bars.svg?inline';
import TimeoutScreen from '@/components/ui/TimeoutScreen.vue';
import {
  round,
  resolveNumber,
  resolveNumberWithUnit
} from '@/common/formatter';
import Flex from '@/components/layout/Flex.vue';
import { chartColor } from '@/composables/home';

export default defineComponent({
  components: {
    BarsSvg,
    Chart,
    Flex,
    TimeoutScreen
  },
  props: {
    isLoading: Boolean,
    isTimeScreen: Boolean,
    period: String,
    data: Array,
    isAccumulation: Boolean,
    index: String,
    title: String,
    type: String
  },
  setup(props) {
    const series = computed(() => {
      if (!props.data || props.data.length === 0) return [];
      return props.data.map((data, i) => ({
        name: data.displayName,
        color: chartColor(i).bar,
        data: data[props.type].map(data => data[props.index])
      }));
    });
    const categories = computed(() => {
      if (!props.data || props.data.length === 0) return [];
      return props.data[0][props.type].map(val =>
        format(new Date(val.date), 'M/d')
      );
    });
    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      }
    });
    // NOTE: barグループのサイズを調整(plotOptions.series.groupPaddingと調整)
    const GROUP_WIDTH = series.value.length * 12 + 30;
    const chartOptions = ref({
      chart: {
        animation: false,
        type: computed(() => (props.isAccumulation ? 'line' : 'column')),
        marginTop: 28,
        marginBottom: 50,
        height: 210,
        minWidth: 500,
        scrollablePlotArea: {
          minWidth:
            series.value.length === 0
              ? 500
              : series.value[0].data.length * GROUP_WIDTH,
          scrollPositionX: 1
        }
      },
      legend: false,
      title: false,
      xAxis: {
        categories
      },
      yAxis: {
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        minorGridLineWidth: 0,
        title: {
          text: props.title,
          align: 'high',
          offset: 0,
          rotation: 0,
          x: 10,
          y: -16,
          style: {
            fontSize: 10
          }
        },
        labels: {
          formatter: function() {
            return resolveNumber(this.value);
          }
        }
      },
      tooltip: {
        formatter: function() {
          if (props.index === 'grp') {
            return `${this.x}<br>${this.series.name}<br>GRP: ${round(
              this.point.y,
              1
            )}`;
          }
          return `${this.x}<br>${this.series.name}<br>${resolveNumberWithUnit(
            this.point.y,
            3,
            '人'
          )}`;
        }
        // shared: true // 個別 or まとめて
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          groupPadding: 0.2,
          pointWidth: 10
        }
      },
      series,
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });
    const width = ref(GROUP_WIDTH * 7);
    const box = ref();
    const resize = () => {
      if (box.value) {
        const Rect = box.value.getBoundingClientRect();
        if (Rect) {
          width.value = Rect.width;
          chartOptions.value.chart.width = width.value;
          if (
            series.value.length === 0 ||
            (series.value[0] && series.value[0].data.length <= 7)
          ) {
            // NOTE: 7日以下のグラフの場合、表示表示領域にリサイズ
            chartOptions.value.chart.scrollablePlotArea.minWidth = width.value;
          }
        }
      }
    };
    onMounted(() => {
      resize();
      window.addEventListener('resize', resize);
    });

    return {
      box,
      width,
      chartOptions
    };
  }
});
</script>
<style lang="scss" scoped>
::deep .highcharts-fixed {
  background: linear-gradient(
    90deg,
    #ffffffab 50px,
    transparent 50px,
    transparent 100%
  );
}
::deep .highcharts-scrollable-mask {
  fill: transparent;
}
.zerohit::after {
  content: '';
  display: block;
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-top: -50px;
  z-index: 5;
  position: relative;
}
</style>
