<template>
  <div
    class="ui-sheet ma-2"
    :class="{ active, elevation, 'no-active': noActive, 'no-hover': noHover }"
  >
    <div class="container">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Sheet',
  props: {
    noActive: Boolean,
    active: Boolean,
    elevation: Boolean,
    noHover: Boolean
  }
});
</script>
<style lang="scss" scoped>
$shadow: 0px 0.6px 1.8px rgba(108, 155, 238, 0.12),
  0px 3.2px 7.2px rgba(108, 155, 238, 0.14);
.ui-sheet {
  display: flex;
  border-radius: 8px;
  background-color: #ffffff;

  &:not(.no-hover):hover {
    background-color: var(--dark-5-color);
    cursor: pointer;
  }
  &:not(.no-active):before {
    content: '';
    display: block;
    border-left: 6px solid;
    border-color: transparent;
    border-radius: 0px 8px 8px 0px;
    margin: 16px 0;
  }
  &.active {
    box-shadow: $shadow;

    &:before {
      border-color: var(--primary-color);
    }
  }
  &.elevation {
    box-shadow: $shadow;
  }
  .container {
    padding: 16px;
    width: 100%;
  }
}
</style>
