<template>
  <Flex justify-center align-center height="100%">
    <template v-if="isPaidWorkspace && !isRestricted">
      <div v-if="isTimeScreen" style="text-align: center;">
        <TimeoutScreen
          reload-class="mt-1"
          message-class="mt-0"
          @reload="$emit('reload')"
        />
      </div>
      <BarsSvg v-else-if="isLoading && !isTimeScreen" />
      <Chart v-else-if="!isLoading && !isTimeScreen" :options="chartOptions" />
    </template>
    <Box
      v-else-if="!isPaidWorkspace || isRestricted"
      style="position: relative"
    >
      <Flex
        justify-center
        align-center
        height="100%"
        width="100%"
        class="cover"
      >
        <PaidPr
          tag="div"
          :is-restricted="isRestricted"
          :is-paid-workspace="isPaidWorkspace"
        >
          <div style="text-align:center" class="pa-2">
            <Icon name="lock" color="disabled" />
            <div v-if="!isRestricted">
              ※ 有料プランから<br />ご利用いただけます
            </div>
            <div v-else>※ メンバー以上の<br />権限が必要です</div>
          </div>
        </PaidPr>
      </Flex>
      <Chart :options="PaidOptions" />
    </Box>
  </Flex>
</template>
<script>
import { Chart } from 'highcharts-vue';
import { computed, defineComponent, ref } from 'vue';

import BarsSvg from '@/assets/images/bars.svg?inline';
import Flex from '@/components/layout/Flex.vue';
import PaidPr from '@/components/ui/PaidPr.vue';
import Icon from '@/components/ui/Icon.vue';
import Box from '@/components/layout/Box.vue';
import TimeoutScreen from '@/components/ui/TimeoutScreen.vue';

import { chartColor } from '@/composables/home';
import { round } from '@/common/formatter';
export default defineComponent({
  components: {
    Chart,
    Flex,
    BarsSvg,
    PaidPr,
    Icon,
    Box,
    TimeoutScreen
  },
  props: {
    isLoading: Boolean,
    isTimeScreen: Boolean,
    data: Array,
    isPaidWorkspace: Boolean,
    isRestricted: Boolean
  },
  setup(props) {
    const series = computed(() => {
      if (!props.data || props.data.length === 0) return [];
      return props.data.map((val, i) => ({
        name: val.productName,
        y: round(val.share, 3),
        grp: val.grp,
        color: chartColor(i).bar,
        dataLabels: {
          color: chartColor(i).text
        }
      }));
    });
    const chartOptions = ref({
      chart: {
        type: 'pie',
        width: 200,
        height: 260,
        margin: 0,
        spacing: 0
      },
      title: {
        y: 14,
        style: {
          fontSize: '14px',
          fontFamily: 'var(--font-family)',
          fontWeight: 'bold'
        },
        text: 'SOV'
      },
      tooltip: {
        outside: true,
        formatter: function() {
          return `${this.point.name}<br>GRP: ${round(this.point.grp, 1)}`;
        }
      },
      legend: false,
      plotOptions: {
        pie: {
          dataLabels: {
            formatter: function() {
              if (round(this.point.percentage, 1) === 0) {
                return '';
              }
              return round(this.point.percentage, 1) + '%';
            },
            distance: -18,
            style: {
              textOverflow: 'nowrap',
              textOutline: 0
            }
          }
        }
      },
      series: [
        {
          name: '',
          data: series,
          size: '100%',
          innerSize: '63%',
          id: 'versions'
        }
      ],
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });
    // 有料訴求用データ
    const PaidOptions = ref({
      ...chartOptions.value,
      series: [
        {
          ...chartOptions.value.series[0],
          data: [
            { color: chartColor(0).bar, y: 40 },
            { color: chartColor(1).bar, y: 30 },
            { color: chartColor(2).bar, y: 20 },
            { color: chartColor(3).bar, y: 10 }
          ]
        }
      ]
    });

    return {
      chartOptions,
      series,
      PaidOptions
    };
  }
});
</script>
<style lang="scss">
.station-donut {
  .highcharts-text-outline {
    stroke: transparent;
  }
}
.cover {
  background: rgb(255 255 255 / 70%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
