<template>
  <div class="ma-4">
    <div class="grid">
      <div class="row thead mb-1">
        <div class="col">
          <Typography color="grey" size="xs" class="nowrap pl-4">
            <span>商品／ブランド</span>
          </Typography>
        </div>
        <div class="col" />
        <div class="col" v-for="(item, key) in config" :key="key">
          <Typography color="grey" size="xs" class="nowrap">
            <span v-html="item.label" />
          </Typography>
        </div>
      </div>
      <ComparableIndexRow
        class="row mb-2"
        primary
        :name="selected.productName || '----'"
        :subName="current.targetName || '--'"
        :is-loading="isLoading"
        :is-time-screen="isTimeScreenSummary"
        :config="config"
        :selected="selected"
        :targetIsEnabled="current.targetIsEnabled !== false"
        :summary="summary"
        @reload="$emit('onReloadSummary')"
      />
      <template v-for="(product, i) in current.competitiveProducts" :key="i">
        <ComparableIndexRow
          v-if="isToggle"
          class="row mb-2"
          :name="product.competitiveProductName"
          :subName="product.competitiveCompanyName"
          :is-loading="isStatusCompetitiveProducts[product.id].isLoading"
          :is-time-screen="isStatusCompetitiveProducts[product.id].isTimeScreen"
          :config="config"
          :selected="selected"
          :targetIsEnabled="true"
          :summary="targets[i]"
          @reload="
            $emit('onReloadCompetitiveProducts', {
              product,
              relatedProductId: current.id
            })
          "
        />
      </template>
    </div>
    <template v-if="isLoadingHome">
      <Flex class="mt-4" justify-center align-center height="32px">
        <ThreeDotsSvg class="loading" :style="{ width: 40, height: 10 }" />
      </Flex>
    </template>
    <template v-else>
      <template v-if="isPaidWorkspace && !isRestricted">
        <Flex
          v-if="
            current.competitiveProducts &&
              current.competitiveProducts.length !== 0
          "
          class="ui-show-more mt-4"
          justify-center
          align-center
          height="32px"
          @click="(isToggle = !isToggle), $emit('onToggle', isToggle)"
        >
          <icon
            sides="17"
            :name="isToggle ? 'chevron-up' : 'chevron-down'"
            color="primary"
            class="mr-2"
          />
          <Typography color="primary" size="s" tag="span">
            競合の指標を{{ isToggle ? '隠す' : '表示' }}
          </Typography>
        </Flex>
        <Flex
          v-else
          class="ui-show-more mt-4"
          :class="{ zerohit: !current.id }"
          justify-center
          align-center
          height="32px"
          @click="
            current.id
              ? onClickTarget(
                  selected.id,
                  !selected.isCmProduct,
                  selected.affiliatedCompanyId
                )
              : false
          "
        >
          <Typography
            :color="current.id ? 'primary' : 'gray'"
            size="s"
            tag="span"
          >
            競合を追加する
          </Typography>
        </Flex>
      </template>
      <template v-else>
        <PaidPr
          tag="div"
          :is-restricted="isRestricted"
          :is-paid-workspace="isPaidWorkspace"
        >
          <Flex justify-center align-center class="mt-4 zerohit" height="32px">
            <icon sides="12" name="lock" color="disabled" class="mr-2" />
            <Typography color="grey" size="s" tag="span">
              競合を追加する
            </Typography>
          </Flex>
        </PaidPr>
      </template>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@/common/format';
import { SETTINGS_ROUTES } from '@/router';

import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';
import {
  round,
  resolveNumber,
  resolveNumberWithUnit,
  resolveUnit
} from '@/common/formatter';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';
import ComparableIndexRow from './ComparableIndexRow.vue';
import PaidPr from '@/components/ui/PaidPr.vue';

export default defineComponent({
  name: 'Home',
  components: {
    ThreeDotsSvg,
    Flex,
    Icon,
    Typography,
    ComparableIndexRow,
    PaidPr
  },
  props: {
    isLoading: Boolean,
    isLoadingHome: Boolean,
    isTimeScreenSummary: Boolean,
    isStatusCompetitiveProducts: Object,
    config: Object,
    targets: Array,
    selected: Object,
    current: Object,
    summary: Object,
    isPaidWorkspace: Boolean,
    isRestricted: Boolean,
    duration: Object
  },
  setup(props) {
    const router = useRouter();
    const isToggle = ref(false);
    watch(
      () => props.selected,
      () => {
        isToggle.value = false;
      }
    );
    // リンク
    const onClickTarget = (brandId, isTvalProduct, affiliatedCompanyId) => {
      router.push({
        name: SETTINGS_ROUTES.brandEdit,
        params: {
          brandId
        },
        query: {
          start: format(props.duration.range.start, DATE_FORMAT),
          end: format(props.duration.range.end, DATE_FORMAT),
          isTvalProduct: isTvalProduct ? 'true' : 'false',
          affiliatedCompanyId: affiliatedCompanyId
        }
      });
    };
    return {
      isToggle,
      round,
      onClickTarget,
      resolveNumberWithUnit,
      resolveNumber,
      resolveUnit
    };
  }
});
</script>

<style lang="scss" scoped>
.ui-show-more {
  background: var(--primary-light-color);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.zerohit {
  background-color: var(--dark-5-color);
  padding: 8px;
  border-radius: 4px;
  cursor: default;
}
.row {
  display: grid;
  grid-template-columns: 160px 76px 1fr 1fr repeat(3, minmax(125px, 1fr));
}
.thead {
  .col {
    padding: 4px 16px 4px 0;
    background: var(--primary-pale-color);
    white-space: nowrap;

    &:not(:first-child) {
      text-align: right;
    }
  }
}
.loading {
  color: var(--chart-blue-2);
}
.loading {
  color: var(--chart-blue-2);
}
</style>
