<template>
  <div>
    <Flex align-center justify-space-between class="pa-2">
      <Typography color="primary" bold class="pa-2">{{ title }}</Typography>
      <slot name="header-buttons" />
    </Flex>
    <Divider />
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';
import Divider from '@/components/ui/Divider.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Flex,
    Typography,
    Divider
  },
  props: {
    brands: Array,
    selected: String,
    title: String
  },
  emits: ['onClickPin', 'onSelected']
});
</script>

<style lang="scss" scoped>
$shadow: 0px 0.6px 1.8px rgba(108, 155, 238, 0.12),
  0px 3.2px 7.2px rgba(108, 155, 238, 0.14);
.ui-sheet {
  display: flex;
  border-radius: 8px;

  &:not(.no-hover):hover {
    background-color: var(--dark-5-color);
    cursor: pointer;
  }
  &.active {
    box-shadow: $shadow;
    background-color: #ffffff;

    &:before {
      content: '';
      display: block;
      border-left: 6px solid;
      border-color: var(--primary-color);
      border-radius: 0px 8px 8px 0px;
      margin: 16px 0;
    }
  }
  &.elevation {
    box-shadow: $shadow;
  }
}
</style>
