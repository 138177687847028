import axios from 'axios';
import { format } from 'date-fns';
import { Ref, ref } from 'vue';

import { requestCanceler } from '@/plugins/axiosCancel';
import { HomeApi } from '@/api';
import {
  AreaInfoIdEnum,
  GrpsForSovProductShare,
  HomeCompetitiveProduct,
  HomeProduct,
  HomeProductDetail,
  HomeSummariess
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import { chartColor } from '@/composables/home';
import { handleError } from '@/common/handleError';

export interface DateRange {
  end: Date;
  start: Date;
}
export interface Legend {
  color: string;
  name: string;
}

export interface IsStatusCompetitiveProducts {
  [id: number]: {
    isTimeScreen: boolean;
    isLoading: boolean;
  };
}

export default class HomeApiHandler {
  placementAmountSov: Ref = ref();
  targetReachNumberByDay: Ref = ref();
  targetReachNumberByWeek: Ref = ref();
  placementAmount: Ref = ref();
  summaryRef: Ref<HomeSummariess> = ref({} as HomeSummariess);
  productsRef: Ref<Array<HomeProduct>> = ref([]);
  tvViewerPopulationRef: Ref<number> = ref(0);
  competitiveProductsRef: Ref<Array<HomeSummariess>> = ref([]);
  selectedRef: Ref<HomeProduct> = ref({} as HomeProduct);
  currentRef: Ref<HomeProductDetail> = ref({} as HomeProductDetail);
  legends = ref<Legend[]>([] as Legend[]);
  isLoadingHome: Ref<boolean> = ref(false);
  isLoadingProducts: Ref<boolean> = ref(false);
  isLoadingSummaries: Ref<boolean> = ref(false);
  isLoadingSOV: Ref<boolean> = ref(false);
  isLoadingGRP: Ref<boolean> = ref(false);
  isLoadingReach: Ref<boolean> = ref(false);

  isStatusCompetitiveProducts: Ref<IsStatusCompetitiveProducts> = ref(
    {} as IsStatusCompetitiveProducts
  );
  isTimeScreenSummary: Ref<boolean> = ref(false);
  isTimeScreenGRP: Ref<boolean> = ref(false);
  isTimeScreenReach: Ref<boolean> = ref(false);
  isTimeScreenSOV: Ref<boolean> = ref(false);

  private start: string;
  private end: string;
  private productId = -1;
  private isCmProduct = false;

  constructor(
    private accountId: number,
    private companyId: number,
    dateRange: DateRange,
    private areaCode?: AreaInfoIdEnum
  ) {
    if (dateRange.start && dateRange.end) {
      this.start = format(dateRange.start, DATE_FORMAT);
      this.end = format(dateRange.end, DATE_FORMAT);
    } else {
      // 起こり得ないことで、もし仮に起こったら実装ミスなので修正するように
      throw Error('date range includes null');
    }
  }

  /**
   * 初期化関数
   * 呼ばれるタイミング：
   * - 初期時
   * - カンパニー変更時
   * - 商品選択時
   * @private
   */
  private async init() {
    this.placementAmountSov.value = null;
    this.targetReachNumberByDay.value = null;
    this.targetReachNumberByWeek.value = null;
    this.placementAmount.value = null;
    this.summaryRef.value = {} as HomeSummariess;
    this.productsRef.value = [];
    this.competitiveProductsRef.value = [];
    this.selectedRef.value = {} as HomeProduct;
    this.currentRef.value = {} as HomeProductDetail;
    this.legends.value = [] as Legend[];

    //
    this.isLoadingProducts.value = true;
    this.isLoadingHome.value = true;
    this.isLoadingGRP.value = true;
    this.isLoadingSOV.value = true;
    this.isLoadingReach.value = true;
    this.isLoadingSummaries.value = true;
    //
    this.fetchStatsTvViewerPopulation().then();
    await this.fetchProducts();
    if (this.productsRef.value.length > 0) {
      const product = this.productsRef.value[0];
      this.selectProduct(product);
    } else {
      // throw Error('implement me');
    }
  }

  /**
   * 商品を選択し直した時に呼ぶ
   * @param product
   */
  selectProduct(product: HomeProduct): void {
    this.productId = product.id;
    this.isCmProduct = product.isCmProduct;
    this.selectedRef.value = product;
    // すべてを空に
    this.placementAmountSov.value = null;
    this.targetReachNumberByDay.value = null;
    this.targetReachNumberByWeek.value = null;
    this.placementAmount.value = null;
    this.summaryRef.value = {} as HomeSummariess;
    this.competitiveProductsRef.value = [];
    this.legends.value = [] as Legend[];
    // APIの一括キャンセル
    requestCanceler.cancel();

    this.fetchAll();
  }

  /**
   * 全てのデータを取得するためのAPIコールまとめ
   * @private
   */
  private fetchAll(): void {
    this.fetchSummaries().then();
    this.fetchPlacementAmount().then();
    this.fetchPlacementAmountSov().then();
    this.fetchTargetReachNumber().then();
    // current(指数表示のtagと競合)は編集中(isCmProduct:true)の場合呼び出さない。
    this.currentRef.value = {} as HomeProductDetail;
    if (!this.isCmProduct) {
      this.fetchProduct().then();
    } else {
      this.isLoadingHome.value = false;
      this.NoneIsCmProduct(this.selectedRef.value.id);
    }
  }

  /**
   * 地域が変更された時に呼ぶ
   * @param area
   */
  selectArea(area: AreaInfoIdEnum): void {
    this.areaCode = area;
    // APIの一括キャンセル
    requestCanceler.cancel();
    this.init().then();
  }
  /**
   * 期間が変更された時に呼ぶ
   * @param dateRange
   */
  selectDuration(dateRange: DateRange): void {
    this.start = format(dateRange.start, DATE_FORMAT);
    this.end = format(dateRange.end, DATE_FORMAT);
    // APIの一括キャンセル
    requestCanceler.cancel();
    this.init().then();
  }

  /**
   * pinを押下したときに呼ぶ
   * @param product
   */
  async postProductPin(product: HomeProduct): Promise<void> {
    try {
      const { id, isCmProduct, isFavorite } = { ...product };
      const ChangeFavoriteProduct = {
        productId: id,
        isFavorite,
        isCmProduct
      };
      await HomeApi.postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(
        this.accountId,
        this.companyId,
        ChangeFavoriteProduct
      );
    } catch (e) {
      handleError(e);
    }
  }
  /**
   * プロダクト一覧取得
   */
  async fetchProducts(): Promise<void> {
    if (this.areaCode) {
      this.isLoadingProducts.value = true;
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdHomeProducts(
          this.accountId,
          this.companyId,
          this.start,
          this.end,
          this.areaCode,
          0,
          999,
          { cancelToken: requestCanceler.token() }
        );
        this.productsRef.value = result.data.list;
        this.isLoadingProducts.value = false;
        if (result.data.list.length === 0) {
          //
          this.isLoadingHome.value = false;
          this.isLoadingSummaries.value = false;
          this.isLoadingGRP.value = false;
          this.isLoadingSOV.value = false;
          this.isLoadingReach.value = false;
        }
      } catch (e) {
        handleError(e);
      } finally {
        // this.isLoadingProducts.value = false;
      }
      // finallyでlaodingをfalseにすると、キャンセルで処理できない
    }
  }
  /**
   * テレビ視聴者人口の取得
   * getStatsTvViewerPopulation
   */
  async fetchStatsTvViewerPopulation(): Promise<void> {
    if (this.areaCode) {
      try {
        const result = await HomeApi.getStatsTvViewerPopulation(
          this.areaCode,
          this.companyId,
          {
            cancelToken: requestCanceler.token()
          }
        );
        this.tvViewerPopulationRef.value = result.data.number;
      } catch (e) {
        handleError(e);
      }
    }
  }

  /**
   * プロダクトの詳細取得
   * 一覧選択時にselectedを見る
   */
  private async fetchProduct(): Promise<void> {
    this.isLoadingHome.value = true;
    // タイムアウト監視を空にする
    this.isStatusCompetitiveProducts.value = {} as IsStatusCompetitiveProducts;
    try {
      const result = await HomeApi.getProductsProductIdHome(this.productId, {
        cancelToken: requestCanceler.token()
      });
      // 競合の取得
      this.competitiveProductsRef.value = new Array(
        result.data.competitiveProducts.length
      ).fill({} as HomeSummariess);
      result.data.competitiveProducts.map(async product => {
        // Loadingとtimeoutの追加
        this.isStatusCompetitiveProducts.value[product.id] = {
          isLoading: true,
          isTimeScreen: false
        };
        try {
          this.competitiveProductsRef.value[
            product.order - 1
          ] = await this.fetchCompetitiveProductSummaries({
            product,
            relatedProductId: result.data.id
          });
          this.isStatusCompetitiveProducts.value[product.id].isLoading = false;
        } catch (e) {
          // 競合のタイムアウト処理
          if (
            e.status === httpCode.timeout ||
            e.status === httpCode.serverError
          ) {
            this.isStatusCompetitiveProducts.value[product.id] = {
              isLoading: false,
              isTimeScreen: true
            };
          }
          this.competitiveProductsRef.value[
            product.order - 1
          ] = {} as HomeSummariess;
        }
      });
      this.currentRef.value = result.data;
      this.isLoadingHome.value = false;
    } catch (e) {
      handleError(e);
      // this.isLoadingHome.value = false;
    } finally {
      // this.isLoadingHome.value = false;
    }
  }
  /**
   * isCmProductがtrueの場合
   * @param id
   */
  private async NoneIsCmProduct(id: number) {
    this.currentRef.value.targetName = '個人全体';
    this.currentRef.value.competitiveProducts = [];
    this.currentRef.value.id = id;
  }

  /**
   * サマリ取得
   */
  async fetchSummaries(): Promise<void> {
    if (this.areaCode) {
      this.isLoadingSummaries.value = true;
      this.isTimeScreenSummary.value = false;
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(
          this.accountId,
          this.companyId,
          this.productId,
          this.start,
          this.end,
          this.areaCode,
          this.isCmProduct,
          false,
          this.productId, // NOTE:API上無視させる。numberを送る必要があるので商品IDをいれている
          { cancelToken: requestCanceler.token() }
        );
        this.summaryRef.value = result.data;
        this.isLoadingSummaries.value = false;
        this.isTimeScreenSummary.value = false;
      } catch (e) {
        if (
          e.status === httpCode.timeout ||
          e.status === httpCode.serverError
        ) {
          this.isLoadingSummaries.value = false;
          this.isTimeScreenSummary.value = true;
          toast({
            title: e.name,
            variant: e.type
          });
        } else {
          handleError(e);
        }
        // this.isLoadingSummarys.value = false;
      } finally {
        // this.isLoadingSummarys.value = false;
      }
    }
  }

  /**
   * 競合の取得
   */
  async fetchCompetitiveProductSummaries({
    product,
    relatedProductId
  }: {
    product: HomeCompetitiveProduct;
    relatedProductId: number;
  }): Promise<HomeSummariess> {
    if (this.areaCode) {
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(
          this.accountId,
          this.companyId,
          product.id,
          this.start,
          this.end,
          this.areaCode,
          false,
          true,
          relatedProductId,
          { cancelToken: requestCanceler.token() }
        );
        return result.data;
      } catch (e) {
        if (
          (axios.isAxiosError(e) && e.response?.status === httpCode.timeout) ||
          httpCode.serverError
        ) {
          throw e;
        } else {
          handleError(e);
        }
      }
      return {} as HomeSummariess;
    }
    return {} as HomeSummariess;
  }

  /**
   * 出稿量取得
   */
  async fetchPlacementAmount(): Promise<void> {
    if (this.areaCode) {
      this.isLoadingGRP.value = true;
      this.isTimeScreenGRP.value = false;
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(
          this.accountId,
          this.companyId,
          this.productId,
          this.start,
          this.end,
          this.areaCode,
          this.isCmProduct,
          { cancelToken: requestCanceler.token() }
        );
        this.placementAmount.value = result.data;
        this.isLoadingGRP.value = false;
      } catch (e) {
        if (
          e.status === httpCode.timeout ||
          e.status === httpCode.serverError
        ) {
          this.isLoadingGRP.value = false;
          this.isTimeScreenGRP.value = true;
          toast({
            title: e.name,
            variant: e.type
          });
        } else {
          handleError(e);
        }
      }
    }
  }

  /**
   * 出稿量SOV取得
   */
  async fetchPlacementAmountSov(): Promise<void> {
    if (this.areaCode) {
      this.isLoadingSOV.value = true;
      this.isTimeScreenSOV.value = false;
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(
          this.accountId,
          this.companyId,
          this.productId,
          this.start,
          this.end,
          this.areaCode,
          this.isCmProduct,
          { cancelToken: requestCanceler.token() }
        );
        this.placementAmountSov.value = result.data;
        this.legends.value = result.data.productShare.map(
          (data: GrpsForSovProductShare, i: number) => ({
            name: data.productName,
            color: chartColor(i).bar
          })
        );
        this.isLoadingSOV.value = false;
      } catch (e) {
        if (
          e.status === httpCode.timeout ||
          e.status === httpCode.serverError
        ) {
          this.isLoadingSOV.value = false;
          this.isTimeScreenSOV.value = true;
          toast({
            title: e.name,
            variant: e.type
          });
        } else {
          handleError(e);
        }
      }
    }
  }

  /**
   * ターゲットリーチ人数取得
   */
  async fetchTargetReachNumber(): Promise<void> {
    if (this.areaCode) {
      this.isLoadingReach.value = true;
      this.isTimeScreenReach.value = false;
      try {
        const result = await HomeApi.getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(
          this.accountId,
          this.companyId,
          this.productId,
          this.start,
          this.end,
          this.areaCode,
          this.isCmProduct,
          { cancelToken: requestCanceler.token() }
        );
        this.targetReachNumberByDay.value = result.data.targetReachNumberByDay;
        this.targetReachNumberByWeek.value =
          result.data.targetReachNumberByWeek;
        this.isLoadingReach.value = false;
      } catch (e) {
        if (
          e.status === httpCode.timeout ||
          e.status === httpCode.serverError
        ) {
          this.isLoadingReach.value = false;
          this.isTimeScreenReach.value = true;
          toast({
            title: e.name,
            variant: e.type
          });
        } else {
          handleError(e);
        }
      }
    }
  }
}
