<template>
  <SidebarContainer title="商品／ブランド">
    <template #header-buttons>
      <TextLink>
        <router-link
          :to="{ name: SETTINGS_ROUTES.brand, params: $route.params }"
        >
          一覧へ
        </router-link>
      </TextLink>
    </template>
    <Flex
      v-if="isLoading"
      justify-center
      align-center
      :height="`${sidebarHeight}px`"
    >
      <Loading />
    </Flex>
    <Box
      v-else
      class="pa-2"
      :height="`${sidebarHeight}px`"
      overflow="hidden auto"
    >
      <div v-if="sortProducts.length === 0" class="zerohit">
        <Typography>表示できる情報がありません</Typography>
      </div>
      <template v-for="(product, i) in sortProducts" :key="i">
        <Sheet
          @click="$emit('onSelected', product)"
          :active="selected.id === product.id"
        >
          <Flex justify-space-between>
            <Box>
              <TypographyEllipsis
                bold
                width="100%"
                line-height="20px"
                :line="2"
                size="m"
              >
                {{ product.productName }}
              </TypographyEllipsis>
            </Box>
            <Flex class="mt-1" flexDirection="column">
              <IconButton
                class="mb-2"
                icon-name="pin"
                size="s"
                :color="product.isFavorite ? 'primary' : 'disabled'"
                @click.stop="$emit('onClickPin', product)"
              />
              <IconButton
                icon-name="edit"
                color="primary"
                sides="16px"
                :disabled="isRestricted"
                @click.stop="
                  onClickTarget(
                    product.id,
                    !product.isCmProduct,
                    product.affiliatedCompanyId
                  )
                "
              />
            </Flex>
          </Flex>
        </Sheet>
      </template>
    </Box>
  </SidebarContainer>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { format } from 'date-fns';
import { DATE_FORMAT } from '@/common/format';

import { resolveNumber } from '@/common/formatter';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Loading from '@/components/ui/Loading';
import TextLink from '@/components/ui/TextLink.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import Sheet from './Sheet.vue';
import { SETTINGS_ROUTES } from '@/router';

import SidebarContainer from './SidebarContainer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Flex,
    Box,
    Typography,
    TypographyEllipsis,
    IconButton,
    TextLink,
    SidebarContainer,
    Loading,
    Sheet
  },
  props: {
    sidebarHeight: Number,
    isLoading: Boolean,
    products: Array,
    selected: Object,
    duration: Object,
    isRestricted: Boolean
  },
  emits: ['onClickPin', 'onSelected'],
  setup(props) {
    const router = useRouter();
    // リンク
    const onClickTarget = (brandId, isTvalProduct, affiliatedCompanyId) => {
      router.push({
        name: SETTINGS_ROUTES.brandEdit,
        params: {
          brandId
        },
        query: {
          start: format(props.duration.range.start, DATE_FORMAT),
          end: format(props.duration.range.end, DATE_FORMAT),
          isTvalProduct: isTvalProduct ? 'true' : 'false',
          affiliatedCompanyId: affiliatedCompanyId
        }
      });
    };
    const sort = products => {
      // PINあり・GRP順で並び替え
      const pins = products.filter(product => product.isFavorite);
      pins.sort((a, b) => (a.grp > b.grp ? -1 : 1));
      // PINなし・GRP順で並び替え
      const notpins = products.filter(product => !product.isFavorite);
      notpins.sort((a, b) => (a.grp > b.grp ? -1 : 1));
      return pins.concat(notpins);
    };
    const sortProducts = ref(sort([...props.products]));
    watch(
      () => props.products,
      products => {
        sortProducts.value = sort([...products]);
      },
      { deep: true }
    );

    return {
      sortProducts,
      resolveNumber,
      onClickTarget,
      SETTINGS_ROUTES
    };
  }
});
</script>

<style lang="scss" scoped>
.zerohit {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
a span {
  text-decoration: underline;
}
</style>
