<template>
  <div class="ui-sheet">
    <div class="col name">
      <div style="width: 100%;">
        <div class="mb-2">
          <TypographyEllipsis
            bold
            size="m"
            width="100%"
            :line="2"
            line-height="1.5"
            style="height:3em;"
          >
            {{ name }}
          </TypographyEllipsis>
        </div>
        <div>
          <Flex align-center>
            <TypographyEllipsis
              size="xs"
              :color="
                !targetIsEnabled && !isLoading
                  ? 'error'
                  : primary
                  ? 'primary'
                  : ''
              "
              :line="2"
              :style="targetIsEnabled ? 'height:2em;' : ''"
            >
              {{ subName }}
            </TypographyEllipsis>
            <Tooltip
              v-if="!targetIsEnabled && !isLoading"
              content="利用できない条件を含むターゲットが設定されているため個人全体で集計しています。
                別のターゲットを指定するか、カスタムターゲットを修正してください。"
              :class="'ml-1'"
            >
              <Icon name="alert" color="error" sides="10" />
            </Tooltip>
          </Flex>
          <TypographyEllipsis
            v-if="!targetIsEnabled && !isLoading"
            size="xs"
            color="disabled"
          >
            {{ '個人全体で表示されています' }}
          </TypographyEllipsis>
        </div>
      </div>
    </div>
    <div class="col nowrap">
      <Typography nowrap size="xl" bold>
        &nbsp;
      </Typography>
      <Typography color="grey" size="xs" class="mt-2">
        前期比
      </Typography>
    </div>
    <template v-if="isLoading">
      <div class="colspan" style="text-align: center;">
        <ThreeDotsSvg class="loading" :style="{ width: 40, height: 10 }" />
      </div>
    </template>
    <template v-else-if="isTimeScreen">
      <div class="colspan" style="text-align: center;">
        <TimeoutScreen
          reload-class="mt-1"
          message-class="mt-0"
          @reload="$emit('reload')"
        />
      </div>
    </template>
    <template v-else-if="!isLoading && !isTimeScreen">
      <div class="col nowrap hover" v-for="(item, key) in config" :key="key">
        <template v-if="!summary || (summary && !summary[key])">
          <Flex align-end justify-end>
            <Typography nowrap size="xl" bold>
              --
            </Typography>
            <Typography size="s" class="nowrap">
              {{ item.unit }}
            </Typography>
          </Flex>
          <Flex class="mt-2" align-center justify-end>
            <Typography size="xs" bold color="inherit" tag="span">
              -- {{ config[key].prevUnit }}
            </Typography>
          </Flex>
        </template>
        <template v-else>
          <Tooltip
            :content="
              roundNumber(summary[key].indicator, item.round).toLocaleString() +
                item.unit
            "
          >
            <Flex align-end justify-end>
              <Typography nowrap size="xl" bold>
                {{
                  key === 'targetReachRate'
                    ? roundNumber(summary[key].indicator, config[key].round)
                    : resolveNumber(summary[key].indicator)
                }}
              </Typography>
              <Typography size="s" class="nowrap">
                {{ resolveUnit(summary[key].indicator, config[key].unit) }}
              </Typography>
            </Flex>
          </Tooltip>
          <Flex
            v-if="
              summary[key].previousIndicator === 0 ||
                summary[key].previousIndicator >= 1000
            "
            class="mt-2"
            align-center
            justify-end
          >
            <Typography
              v-if="summary[key].previousIndicator === 0"
              size="xs"
              bold
              color="inherit"
              tag="span"
            >
              -- {{ config[key].prevUnit }}
            </Typography>
            <Typography v-else size="xs" bold color="inherit" tag="span">
              -
            </Typography>
          </Flex>
          <Flex
            v-else
            class="mt-2"
            align-center
            justify-end
            :class="fluctuate(round(summary[key].previousIndicator, 2))"
          >
            <Typography size="s" bold color="inherit" tag="span">
              <icon
                v-if="round(summary[key].previousIndicator, 2) !== 0"
                sides="8"
                :name="
                  `arrow-${fluctuate(round(summary[key].previousIndicator, 2))}`
                "
                color="inherit"
              />
              {{ round(summary[key].previousIndicator, 2).toFixed(1) }}
              {{ config[key].prevUnit }}
            </Typography>
          </Flex>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';
import {
  round,
  roundNumber,
  resolveNumber,
  resolveNumberWithUnit,
  resolveUnit
} from '@/common/formatter';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import TimeoutScreen from '@/components/ui/TimeoutScreen.vue';

export default defineComponent({
  name: 'Home',
  components: {
    ThreeDotsSvg,
    Flex,
    Icon,
    Typography,
    TypographyEllipsis,
    TimeoutScreen,
    Tooltip
  },
  props: {
    primary: Boolean,
    name: String,
    subName: String,
    isLoading: Boolean,
    isTimeScreen: Boolean,
    config: Object,
    selected: Object,
    targets: Array,
    targetIsEnabled: {
      type: Boolean,
      default: true
    },
    summary: Object
  },
  setup() {
    const fluctuate = value => {
      switch (Math.sign(value)) {
        case -1:
          return 'down';
        case 0:
          return '';
        case 1:
          return 'up';
      }
    };

    return {
      fluctuate,
      round,
      roundNumber,
      resolveNumberWithUnit,
      resolveNumber,
      resolveUnit
    };
  }
});
</script>

<style lang="scss" scoped>
.ui-sheet {
  box-shadow: 0px 0.6px 1.8px rgba(108, 155, 238, 0.12),
    0px 3.2px 7.2px rgba(108, 155, 238, 0.14);
  border-radius: 8px;
}
.col {
  padding: 16px;
  letter-spacing: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.name {
    display: flex;
    padding-right: 0;
    width: 160px;

    &::after {
      content: '';
      margin-left: 16px;
      border-right: 1px solid var(--dark-5-color);
    }
  }
  &.hover:hover {
    background: var(--dark-5-color);
  }
  &.nowrap {
    white-space: nowrap;
  }
}
.colspan {
  grid-column: 3 / span 5;

  display: flex;
  justify-content: center;
  align-items: center;
}
.up {
  color: var(--green-dark-color);
}
.down {
  color: var(--red-blue_red-5);
}
.loading {
  color: var(--chart-blue-2);
}
</style>
