<template>
  <LayoutWithSidebar>
    <template #button-content>
      <div style="width: 100%;">
        <Flex align-center>
          <Typography>期間：</Typography>
          <Select
            v-model="duration"
            placeholder=""
            width="240px"
            class="mr-4 dense"
            :options="refDurationOptions"
            @change="onDurationChange"
          />
          <Typography>エリア：</Typography>
          <SelectArea
            :key="areaOptions"
            v-model="area"
            placeholder=""
            width="110px"
            class="mr-4 dense"
            dense
            :select-options="areaOptions"
            @select="onAreaChange"
            :is-loading="isLoadingAreaOptions"
          />
          <div class="ui-chip">
            <Flex class="brand-lift-summaries-header-left" align-center>
              <Icon name="tv-alt" color="primary" sides="12px" class="mr-1" />
              <Typography size="s" class="mr-2" color="primary">
                テレビ視聴人口
              </Typography>
              <Typography size="s">
                {{
                  tvViewerPopulation
                    ? resolveNumberWithUnit(tvViewerPopulation, 3, '人')
                    : `-`
                }}
              </Typography>
            </Flex>
          </div>
        </Flex>
      </div>
    </template>
    <div>
      <Card padless style="background: #F9FAFA">
        <Flex>
          <Box
            min-width="300px"
            width="300px"
            class="card-box-left mr-6"
            background-color="#ffffff"
          >
            <SidebarProduct
              :is-loading="isLoadingProducts"
              :products="products"
              :selected="selected"
              :sidebar-height="sidebarHeight"
              :duration="duration"
              :is-restricted="isRestricted"
              @onClickPin="onClickPin"
              @onSelected="onSelected"
            />
          </Box>
          <Box flex="1" class="card-box-right" background-color="#ffffff">
            <PaidModal v-if="!isPaidWorkspace">
              <PaidPrBanner />
            </PaidModal>
            <div ref="productBrand">
              <ComparableIndex
                :is-loading="isLoadingSummarys"
                :is-loading-home="isLoadingHome"
                :is-time-screen-summary="isTimeScreenSummary"
                :is-status-competitive-products="isStatusCompetitiveProducts"
                :is-paid-workspace="isPaidWorkspace"
                :is-restricted="isRestricted"
                :current="current"
                :selected="selected"
                :targets="competitiveProducts"
                :summary="summary"
                :config="summariesConfig"
                :duration="duration"
                @onToggle="v => (isToggle = v)"
                @onReloadSummary="onReloadSummary"
                @onReloadCompetitiveProducts="onReloadCompetitiveProducts"
              />
              <div class="ma-4">
                <Flex>
                  <Tabs :tab-list="tabList" class="mr-2" @change="changeTab" />
                  <RadioButtonGroup
                    v-model="period"
                    name="period"
                    size="s"
                    class="mr-2"
                    :buttons="periodButtons"
                  />
                  <Switch v-model="isAccumulation" label="累積" />
                </Flex>
                <Flex justify-center>
                  <Box
                    width="70%"
                    min-height="260px"
                    class="mt-10"
                    v-if="activeTabId === 'grp'"
                  >
                    <Flex
                      justify-center
                      align-center
                      height="210px"
                      v-if="isLoadingGRP"
                    >
                      <BarsSvg />
                    </Flex>
                    <template v-else>
                      <BarChart
                        v-if="period === 'daily'"
                        :is-loading="isLoadingGRP"
                        :is-time-screen="isTimeScreenGRP"
                        type="numbersOfGrp"
                        :data="
                          placementAmount
                            ? placementAmount.grpByDay[
                                isAccumulation
                                  ? 'accumulationPerPeriod'
                                  : 'byPeriod'
                              ]
                            : []
                        "
                        title="(個人GRP)"
                        index="grp"
                        :period="period"
                        :is-accumulation="isAccumulation"
                        @reload="onReloadGRP"
                      />
                      <BarChart
                        v-if="period === 'weekly'"
                        :is-loading="isLoadingGRP"
                        :is-time-screen="isTimeScreenGRP"
                        type="numbersOfGrp"
                        :data="
                          placementAmount
                            ? placementAmount.grpByWeek[
                                isAccumulation
                                  ? 'accumulationPerPeriod'
                                  : 'byPeriod'
                              ]
                            : []
                        "
                        title="(個人GRP)"
                        index="grp"
                        :period="period"
                        :is-accumulation="isAccumulation"
                        @reload="onReloadGRP"
                      />
                    </template>
                  </Box>
                  <Box
                    width="70%"
                    min-height="260px"
                    class="mt-10"
                    v-if="activeTabId === 'reach'"
                  >
                    <Flex
                      justify-center
                      align-center
                      height="210px"
                      v-if="isLoadingReach"
                    >
                      <BarsSvg />
                    </Flex>
                    <template v-else>
                      <BarChart
                        v-if="period === 'daily'"
                        :is-loading="isLoadingReach"
                        :is-time-screen="isTimeScreenReach"
                        type="numbersOfTargetReach"
                        :data="
                          (targetReachNumberByDay &&
                            targetReachNumberByDay[
                              isAccumulation
                                ? 'accumulationPerPeriod'
                                : 'byPeriod'
                            ]) ||
                            []
                        "
                        title="(万人)"
                        index="numberOfPeople"
                        :period="period"
                        :is-accumulation="isAccumulation"
                        @reload="onReloadReach"
                      />
                      <BarChart
                        v-if="period === 'weekly'"
                        type="numbersOfTargetReach"
                        :is-loading="isLoadingReach"
                        :is-time-screen="isTimeScreenReach"
                        :data="
                          (targetReachNumberByDay &&
                            targetReachNumberByWeek[
                              isAccumulation
                                ? 'accumulationPerPeriod'
                                : 'byPeriod'
                            ]) ||
                            []
                        "
                        title="(万人)"
                        index="numberOfPeople"
                        :period="period"
                        :is-accumulation="isAccumulation"
                        @reload="onReloadReach"
                      />
                    </template>
                  </Box>
                  <Box width="30%" min-height="260px">
                    <SOVDonut
                      :is-loading="isLoadingSOV"
                      :is-time-screen="isTimeScreenSOV"
                      :is-paid-workspace="isPaidWorkspace"
                      :is-restricted="isRestricted"
                      :data="
                        placementAmountSov
                          ? placementAmountSov.productShare
                          : []
                      "
                      @reload="onReloadSOV"
                    />
                  </Box>
                </Flex>
                <Flex justify-center class="legends">
                  <Typography
                    v-for="(legend, i) in legends"
                    :key="i"
                    size="xs"
                    class="mx-2 legend"
                  >
                    <span :style="{ color: legend.color }">
                      ●
                    </span>
                    {{ legend.name }}
                  </Typography>
                </Flex>
              </div>
            </div>
          </Box>
        </Flex>
      </Card>
    </div>
  </LayoutWithSidebar>
</template>

<script>
import { defineComponent, ref, watch, nextTick, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { resolveNumberWithUnit } from '@/common/formatter';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Card from '@/components/ui/Card.vue';
import Icon from '@/components/ui/Icon.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import Select from '@/components/ui/Select.vue';
import SelectArea from '@/components/ui/SelectArea.vue';
import Switch from '@/components/ui/Switch.vue';
import Tabs from '@/components/ui/Tabs.vue';
import Typography from '@/components/ui/Typography.vue';
import { useAcl } from '@/store/acl';
import BarsSvg from '@/assets/images/bars.svg?inline';
import PaidPrBanner from './PaidPrBanner.vue';
import PaidModal from '@/components/ui/PaidModal.vue';

import {
  useHome,
  periodButtons,
  tabList,
  summariesConfig
} from '@/composables/home';
import { useUserInfoStore } from '@/store/userInfo';

import {
  JoinWorkspacePaymentTypeEnum,
  JoinWorkspaceAccountPrivilegeEnum
} from '@/api/openapi';

import BarChart from './BarChart.vue';
import ComparableIndex from './ComparableIndex.vue';
import SidebarProduct from './SidebarProduct.vue';
import SOVDonut from './SOVDonut.vue';
import { AclRules } from '@/plugins/acl';

export default defineComponent({
  name: 'CmCompanyMain',
  components: {
    LayoutWithSidebar,
    Flex,
    Box,
    Card,
    Icon,
    Select,
    SelectArea,
    Typography,
    Tabs,
    Switch,
    RadioButtonGroup,
    SidebarProduct,
    SOVDonut,
    BarChart,
    ComparableIndex,
    BarsSvg,
    PaidPrBanner,
    PaidModal
  },
  setup() {
    const isToggle = ref(false);
    const userInfoStore = useUserInfoStore();
    const acl = useAcl();
    const route = useRoute();
    const router = useRouter();
    const companyId = Number(route.params.companyId);
    const accountId = userInfoStore.user.id;
    const productBrand = ref(null);
    const sidebarHeight = ref(400);
    // チャットボットの起動
    const openChatBot = () => {
      window.ChannelIO('showMessenger');
    };
    const {
      tvViewerPopulation,
      //
      duration,
      durationOptions,
      onDurationChange,
      changeDuration,
      getDurationOptions,
      //
      area,
      areaOptions,
      isLoadingAreaOptions,
      onAreaChange,
      //
      isLoadingHome,
      isLoadingProducts,
      isLoadingSummarys,
      isLoadingSOV,
      isLoadingGRP,
      isLoadingReach,
      //
      isStatusCompetitiveProducts,
      isTimeScreenSummary,
      isTimeScreenReach,
      isTimeScreenSOV,
      isTimeScreenGRP,
      onReloadSummary,
      onReloadCompetitiveProducts,
      onReloadSOV,
      onReloadGRP,
      onReloadReach,
      //
      summary,
      competitiveProducts,
      changeTab,
      activeTabId,
      period,
      isAccumulation,
      legends,
      placementAmount,
      placementAmountSov,
      targetReachNumberByDay,
      targetReachNumberByWeek,
      onClickPin,
      onSelected,
      selected,
      current,
      products
    } = useHome({
      companyId,
      accountId
    });

    // サイドバーのスクロール描写の高さ調整
    watch(
      () => [
        isLoadingSummarys.value,
        isLoadingSOV.value,
        isLoadingGRP.value,
        isLoadingReach.value,
        isToggle.value
      ],
      () => {
        nextTick(() => {
          if (productBrand.value) {
            const Rect = productBrand.value.getBoundingClientRect();
            if (Rect) {
              sidebarHeight.value = Rect.height;
            }
          }
        });
      }
    );
    watch(
      () => route.params.companyId,
      () => {
        const isCurrentCompany = userInfoStore.joinableCompanies.filter(
          v => v.companyId === Number(route.params.companyId)
        );
        if (isCurrentCompany.length !== 0) {
          router.go({ path: router.currentRoute.path, force: true });
        }
      }
    );

    // 有料/無料/制限の判定
    const isPaidWorkspace = ref(false);
    const isRestricted = ref(false);
    const refDurationOptions = ref(durationOptions);
    const currentWorkspace = computed(() => {
      return userInfoStore.currentWorkspaceFromRoute(route);
    });
    watch(
      () => currentWorkspace.value,
      () => {
        isPaidWorkspace.value =
          currentWorkspace.value?.paymentType ===
          JoinWorkspacePaymentTypeEnum.Paid;
        isRestricted.value =
          userInfoStore.currentWorkspaceFromRoute(route)?.accountPrivilege ===
          JoinWorkspaceAccountPrivilegeEnum.Restricted;
        refDurationOptions.value = refDurationOptions.value.map(
          (durationOption, index) => {
            if (index === 0) {
              return durationOption;
            }
            durationOption.$isDisabled =
              !isPaidWorkspace.value || isRestricted.value;
            return durationOption;
          }
        );
      }
    );

    watch(
      () => area.value,
      () => {
        const updateDurationOptions =
          area.value !== 'SAAS_KANTO' &&
          area.value !== 'SAAS_KANSAI' &&
          area.value !== 'SAAS_CHUKYO'
            ? getDurationOptions(-3, 5)
            : getDurationOptions(-1, 15);
        refDurationOptions.value = updateDurationOptions.map(
          (durationOption, index) => {
            if (index === 0) {
              return durationOption;
            }
            durationOption.$isDisabled =
              !isPaidWorkspace.value || isRestricted.value;
            return durationOption;
          }
        );
        const selectIndex = updateDurationOptions.findIndex(
          ({ value }) => value === duration.value.value
        );
        changeDuration(updateDurationOptions[selectIndex]);
        duration.value = updateDurationOptions[selectIndex];
      }
    );

    return {
      resolveNumberWithUnit,
      //
      isToggle,
      openChatBot,
      //
      productBrand,
      sidebarHeight,
      //
      duration,
      refDurationOptions,
      onDurationChange,
      //
      area,
      areaOptions,
      isLoadingAreaOptions,
      onAreaChange,
      //
      isLoadingHome,
      isLoadingProducts,
      isLoadingSummarys,
      isLoadingSOV,
      isLoadingGRP,
      isLoadingReach,
      //
      isStatusCompetitiveProducts,
      isTimeScreenSummary,
      isTimeScreenReach,
      isTimeScreenSOV,
      isTimeScreenGRP,
      onReloadSummary,
      onReloadCompetitiveProducts,
      onReloadSOV,
      onReloadGRP,
      onReloadReach,
      //
      tvViewerPopulation,
      //
      summariesConfig,
      summary,
      competitiveProducts,
      tabList,
      changeTab,
      activeTabId,
      periodButtons,
      period,
      isAccumulation,
      legends,
      placementAmount,
      placementAmountSov,
      targetReachNumberByDay,
      targetReachNumberByWeek,
      onClickPin,
      onSelected,
      selected,
      current,
      products,
      //
      isPaidWorkspace,
      isRestricted,
      acl,
      AclRules
    };
  }
});
</script>

<style lang="scss" scoped>
.nowrap {
  white-space: nowrap;
}

.ui-chip {
  display: inline-block;
  border-radius: 1em;
  padding: 4px 8px;
  background-color: var(--primary-pale-color);
  white-space: nowrap;
}
</style>

<style lang="scss" scoped>
.legends {
  > .legend {
    max-width: 25%;
    min-width: auto;
  }
}
</style>

<style lang="scss" scoped>
.ui-free {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #ffffff;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
.card-box-left {
  border-radius: 16px 0 0 16px;
}

.card-box-right {
  border-radius: 0 16px 16px 0;
}
</style>
