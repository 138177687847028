import { format, isAfter } from 'date-fns';
import addDays from 'date-fns/addDays';
import { Ref, ref } from 'vue';

import {
  GrpsForSov,
  HomePlacementAmount,
  HomeProduct,
  HomeCompetitiveProduct,
  HomeProductDetail,
  HomeSummariess,
  HomeTargetReachNumberTargetReachNumberByDay,
  AreaInfoIdEnum,
  SelectableAreasList,
  AreaInfo
} from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { useTabs } from '@/composables/tabs';
import { httpCode } from '@/common/constant';

import HomeApiHandler, {
  DateRange,
  Legend,
  IsStatusCompetitiveProducts
} from './api';
import { useAreaOptions } from '@/store/areaOptions';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { getAvailableDate } from '@/common/dates';
import { INVALID_DATE_TOAST } from '@/common/messages';

interface OnReloadCompetitiveProducts {
  product: HomeCompetitiveProduct;
  relatedProductId: number;
}

interface HomeReturnType {
  activeTabId: Ref<string>;
  //
  area: Ref<AreaInfoIdEnum | undefined>;
  areaOptions: Ref<Array<SelectableAreasList>>;
  isLoadingAreaOptions: Ref<boolean>;
  changeTab: (tabId: string) => Promise<void>;
  competitiveProducts: Ref<Array<HomeSummariess>>;
  duration: Ref<Duration>;
  durationOptions: Duration[];
  isAccumulation: Ref<boolean>;
  //
  isLoadingHome: Ref<boolean>;
  isLoadingSummarys: Ref<boolean>;
  isLoadingGRP: Ref<boolean>;
  isLoadingProducts: Ref<boolean>;
  isLoadingReach: Ref<boolean>;
  isLoadingSOV: Ref<boolean>;
  isLoadingTarget: Ref<boolean>;
  isStatusCompetitiveProducts: Ref<IsStatusCompetitiveProducts>;
  isTimeScreenSummary: Ref<boolean>;
  isTimeScreenReach: Ref<boolean>;
  isTimeScreenSOV: Ref<boolean>;
  isTimeScreenGRP: Ref<boolean>;
  onReloadSummary: () => void;
  onReloadCompetitiveProducts: ({
    product,
    relatedProductId
  }: OnReloadCompetitiveProducts) => void;
  onReloadSOV: () => void;
  onReloadGRP: () => void;
  onReloadReach: () => void;
  //
  legends: Ref<Legend[]>;
  onAreaChange: (select: AreaInfo) => void;
  onClickPin: (product: HomeProduct) => void;
  changeDuration: (select: Duration) => void;
  onDurationChange: (select: Duration) => void;
  onSelected: (product: HomeProduct) => void;
  period: Ref<string>;
  placementAmount: Ref<HomePlacementAmount>;
  placementAmountSov: Ref<GrpsForSov>;
  products: Ref<HomeProduct[]>;
  selected: Ref<HomeProduct>;
  current: Ref<HomeProductDetail>;
  summary: Ref<HomeSummariess>;
  targetReachNumberByDay: Ref<HomeTargetReachNumberTargetReachNumberByDay>;
  targetReachNumberByWeek: Ref<HomeTargetReachNumberTargetReachNumberByDay>;
  //
  tvViewerPopulation: Ref<number>;
  getDurationOptions: (endValue: number, standardTime: number) => Duration[];
}

interface Tab {
  id: string;
  name: string;
}

interface PeriodButton {
  label: string;
  value: string;
}

// ヘッダー
export interface Duration {
  label: string;
  range: DateRange;
  value: string;
}

export const chartColor = (i: number): { bar: string; text: string } => {
  let bar = '';
  let text = '';
  switch (i) {
    case 0:
      bar = 'var(--chart-color-1)';
      text = '#ffffff';
      break;
    case 1:
      bar = 'var(--chart-color-2)';
      text = '#ffffff';
      break;
    case 2:
      bar = 'var(--chart-color-3)';
      text = '#ffffff';
      break;
    case 3:
      bar = 'var(--chart-color-4)';
      text = '#ffffff';
      break;
  }
  return { bar, text };
};

// 指数表示: ラベル
export const summariesConfig = {
  grp: { label: '個人GRP', round: 1, unit: '', prevUnit: '%' },
  numberOfTotalViews: {
    label: '延べ視聴回数',
    round: 1,
    unit: '回',
    prevUnit: '%'
  },
  numberOfTargetViews: {
    label: 'ターゲット視聴回数',
    round: 1,
    unit: '回',
    prevUnit: '%'
  },
  numberOfTargetReaches: {
    label: 'ターゲットリーチ人数',
    round: 1,
    unit: '人',
    prevUnit: '%'
  },
  targetReachRate: {
    label: 'ターゲットリーチ率',
    round: 2,
    unit: '%',
    prevUnit: 'pt'
  }
};
// グラフ: タブラベル
export const tabList: Tab[] = [
  { id: 'grp', name: '出稿量（個人GRP）' },
  { id: 'reach', name: 'ターゲットリーチ人数' }
];
// グラフ: 期間切り替えラベル
export const periodButtons: PeriodButton[] = [
  {
    label: '日別',
    value: 'daily'
  },
  {
    label: '週別',
    value: 'weekly'
  }
];
export const useHome = ({
  companyId,
  accountId
}: {
  accountId: number;
  companyId: number;
  duration: Ref<Duration>;
}): HomeReturnType => {
  const route = useRoute();
  const areaOptionStore = useAreaOptions();
  areaOptionStore.suppressToast = false;
  const {
    selectOptions: areaOptions,
    isLoading: isLoadingAreaOptions
  } = storeToRefs(areaOptionStore);
  // duration
  const getTimePeriodByArea = (startEndValue: number, standardTime: number) => {
    // 東阪名では14時・東阪名以外では5時を境に期間を調整する
    // https://www.notion.so/switchm/7c6b34269f8548c5b53afa5d4132186d
    const adjust = isAfter(new Date(), new Date().setHours(standardTime, 0, 0))
      ? 0
      : -1;
    return format(addDays(new Date(), startEndValue + adjust), DATE_FORMAT);
  };
  const getDurationOptions = (endValue: number, standardTime: number) => {
    const standardDuration = -7;

    const startValue1w = standardDuration + endValue + 1;
    const startValue4w = standardDuration * 4 + endValue + 1;
    const startValue3m = standardDuration * 13 + endValue + 1;
    const startValue6m = standardDuration * 26 + endValue + 1;

    return [
      {
        label: `1週間 ${getTimePeriodByArea(
          startValue1w,
          standardTime
        )} 〜 ${getTimePeriodByArea(endValue, standardTime)}`,
        value: '1w',
        range: {
          start: new Date(getTimePeriodByArea(startValue1w, standardTime)),
          end: new Date(getTimePeriodByArea(endValue, standardTime))
        }
      },
      {
        label: `4週間 ${getTimePeriodByArea(
          startValue4w,
          standardTime
        )} 〜 ${getTimePeriodByArea(endValue, standardTime)}`,
        value: '4w',
        range: {
          start: new Date(getTimePeriodByArea(startValue4w, standardTime)),
          end: new Date(getTimePeriodByArea(endValue, standardTime))
        }
      },
      {
        label: `3か月 ${getTimePeriodByArea(
          startValue3m,
          standardTime
        )} 〜 ${getTimePeriodByArea(endValue, standardTime)}`,
        value: '3m',
        range: {
          start: new Date(getTimePeriodByArea(startValue3m, standardTime)),
          end: new Date(getTimePeriodByArea(endValue, standardTime))
        }
      },
      {
        label: `6か月 ${getTimePeriodByArea(
          startValue6m,
          standardTime
        )} 〜 ${getTimePeriodByArea(endValue, standardTime)}`,
        value: '6m',
        range: {
          start: new Date(getTimePeriodByArea(startValue6m, standardTime)),
          end: new Date(getTimePeriodByArea(endValue, standardTime))
        }
      }
    ];
  };
  const durationOptions = getDurationOptions(-1, 15);
  const duration = ref(durationOptions[0]);
  const area = ref<AreaInfoIdEnum>();
  const validateDate = () => {
    const availableDate = getAvailableDate(area.value);
    if (
      duration.value &&
      (duration.value.range.start < availableDate ||
        duration.value.range.end < availableDate)
    ) {
      const _area = areaOptionStore.option(area.value);
      INVALID_DATE_TOAST(availableDate, _area);
    }
  };
  const changeDuration = (select: Duration) => {
    duration.value = select;
    homeApi.selectDuration(duration.value.range);
  };
  const onDurationChange = (select: Duration) => {
    changeDuration(select);
    validateDate();
  };
  const onAreaChange = (val: AreaInfo) => {
    console.info(val);
    area.value = val.id;
    if (area.value) homeApi.selectArea(area.value);
    validateDate();
  };
  // API
  const homeApi = new HomeApiHandler(
    accountId,
    companyId,
    duration.value.range,
    area.value
  );
  areaOptionStore.fetch(route).then(value => {
    if (value) {
      area.value = value;
      homeApi.selectArea(value);
    }
  });

  // header: テレビ視聴者人口
  const tvViewerPopulation = homeApi.tvViewerPopulationRef;
  // Sidebar: 商品／ブランド
  const products = homeApi.productsRef;
  const selected = homeApi.selectedRef;
  const current = homeApi.currentRef;
  // Sidebar: 商品／ブランド: 選択
  const onSelected = (product: HomeProduct) => {
    homeApi.selectProduct(product);
  };
  // Sidebar: 商品／ブランド: Pin立て
  const onClickPin = (product: HomeProduct) => {
    product.isFavorite = !product.isFavorite;
    homeApi.postProductPin(product);
  };
  const summary = homeApi.summaryRef;
  // グラフ: タブ切り替え
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const changeTab = async (tabId: string) => {
    _changeTab(tabId);
  };
  // グラフ: 期間切り替え
  const period = ref('daily');
  // グラフ: タイプ切り替
  const isAccumulation = ref(false);
  const isLoadingTarget = ref(false);

  const placementAmountSov = homeApi.placementAmountSov as Ref<GrpsForSov>;
  const placementAmount = homeApi.placementAmount as Ref<HomePlacementAmount>;
  const targetReachNumberByDay = homeApi.targetReachNumberByDay as Ref<
    HomeTargetReachNumberTargetReachNumberByDay
  >;
  const targetReachNumberByWeek = homeApi.targetReachNumberByWeek as Ref<
    HomeTargetReachNumberTargetReachNumberByDay
  >;
  const legends = homeApi.legends;
  const competitiveProducts = homeApi.competitiveProductsRef;

  const isLoadingHome = homeApi.isLoadingHome;
  const isLoadingProducts = homeApi.isLoadingProducts;
  const isLoadingSummarys = homeApi.isLoadingSummaries;
  const isLoadingSOV = homeApi.isLoadingSOV;
  const isLoadingGRP = homeApi.isLoadingGRP;
  const isLoadingReach = homeApi.isLoadingReach;

  // タイムアウト時の処理
  const isStatusCompetitiveProducts = homeApi.isStatusCompetitiveProducts;
  const isTimeScreenSummary = homeApi.isTimeScreenSummary;
  const isTimeScreenReach = homeApi.isTimeScreenReach;
  const isTimeScreenSOV = homeApi.isTimeScreenSOV;
  const isTimeScreenGRP = homeApi.isTimeScreenGRP;
  const onReloadSummary = async () => {
    isLoadingSummarys.value = true;
    summary.value = {} as HomeSummariess;
    await homeApi.fetchSummaries();
  };
  const onReloadSOV = async () => {
    isLoadingSOV.value = true;
    await homeApi.fetchPlacementAmountSov();
  };
  const onReloadGRP = async () => {
    isLoadingGRP.value = true;
    await homeApi.fetchPlacementAmount();
  };
  const onReloadReach = async () => {
    isLoadingReach.value = true;
    await homeApi.fetchTargetReachNumber();
  };

  const onReloadCompetitiveProducts = async ({
    product,
    relatedProductId = 0
  }: {
    product: HomeCompetitiveProduct;
    relatedProductId: number;
  }) => {
    // loadingの処理
    isStatusCompetitiveProducts.value[product.id].isLoading = true;
    isStatusCompetitiveProducts.value[product.id].isTimeScreen = false;
    try {
      competitiveProducts.value[
        product.order - 1
      ] = (await homeApi.fetchCompetitiveProductSummaries({
        product,
        relatedProductId
      })) as HomeSummariess;
      isStatusCompetitiveProducts.value[product.id].isLoading = false;
    } catch (e) {
      // 競合のタイムアウト処理
      if (e.response?.status === httpCode.timeout || httpCode.serverError) {
        isStatusCompetitiveProducts.value[product.id] = {
          isLoading: false,
          isTimeScreen: true
        };
      }
      competitiveProducts.value[product.order - 1] = {} as HomeSummariess;
    }
  };
  return {
    tvViewerPopulation,
    //
    duration,
    durationOptions,
    getDurationOptions,
    changeDuration,
    onDurationChange,
    //
    area,
    areaOptions,
    isLoadingAreaOptions,
    onAreaChange,
    //
    isLoadingHome,
    isLoadingProducts,
    isLoadingSummarys,
    isLoadingSOV,
    isLoadingGRP,
    isLoadingReach,
    //
    isStatusCompetitiveProducts,
    isTimeScreenSummary,
    isTimeScreenReach,
    isTimeScreenSOV,
    isTimeScreenGRP,
    onReloadCompetitiveProducts,
    onReloadSummary,
    onReloadSOV,
    onReloadGRP,
    onReloadReach,
    onClickPin,
    onSelected,
    selected,
    current,
    products,
    //
    legends,
    summary,
    competitiveProducts,
    changeTab,
    activeTabId,
    period,
    isAccumulation,
    placementAmount,
    placementAmountSov,
    targetReachNumberByDay,
    targetReachNumberByWeek,
    isLoadingTarget
  };
};
