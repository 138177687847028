
import { defineComponent, ref, watch } from 'vue';
import RadioButton from '@/components/ui/RadioButton.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default defineComponent({
  name: 'RadioButtonGroup',
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    buttons: {
      type: [Object],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String, // m | s | xs
      default: 'm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: String,
    width: String,
    zoomFunc: Function
  },
  components: { RadioButton, Tooltip },
  setup(props, { emit }) {
    const val = ref(props.modelValue);
    const onChange = (event: { target: HTMLInputElement }) => {
      emit('update:modelValue', event.target.value);

      if (props.zoomFunc && typeof props.zoomFunc === 'function') {
        props.zoomFunc(+event.target.value);
      }
    };
    watch(
      () => props.modelValue,
      () => (val.value = props.modelValue)
    );
    return {
      val,
      onChange
    };
  }
});
