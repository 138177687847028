import { StationViewingRate, TvProgramTable } from '@/api/openapi/api';
import { formatDate } from '@/common/format';

export type AreaCode = 'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO';
export type TargetType = 'INDIVIDUAL' | 'HOUSEHOLD' | 'BASIC';
export const STATION_CODE_MAP = {
  SAAS_KANTO: ['NHK', 'NTV', 'EX', 'TBS', 'TX', 'CX'],
  SAAS_KANSAI: ['NHK', 'MBS', 'ABC', 'TVO', 'KTV', 'YTV'],
  SAAS_CHUKYO: ['NHK', 'THK', 'CTV', 'CBC', 'NBN', 'TVA']
};

// ヘッドライン用のリアルタイムデータ
export interface HeadlineStationData {
  stationCode: string;
  stationName: string;
  viewingRate: number;
  programName: string;
  color: string;
}

// 初期表示データ
export interface InstantData {
  timestampList: Array<string>;
  dataSize: number;
  stations: Array<StationViewingRate>;
  tvProgramTable: Array<TvProgramTable>;
}

export const getMaxTargetDate = (): Date => {
  const now = new Date();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const date = new Date(now);
  if ((hour >= 0 && hour < 5) || (hour === 5 && minute < 5)) {
    date.setDate(now.getDate() - 1);
  }
  date.setHours(0, 0, 0, 0);
  return date;
};

// 日付の単位までの比較
export const isDateBefore = (date1: Date, date2: Date): boolean => {
  return formatDate(date1) < formatDate(date2);
};

export const parseDate = (date: string): Date => {
  return new Date(date.replace(' ', 'T') + ':00');
};
